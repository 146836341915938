import React from "react"

import {Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography} from "@mui/material";

export default function DismountConfirmationDialog(props: any) {

    const {t, openDismountDialog, setOpenDismountDialog, onDismount} = props

    return (
        <Dialog open={Boolean(openDismountDialog)} maxWidth={"md"} fullWidth>
            <DialogTitle>
                {t("dismount_dialog_title")}
            </DialogTitle>
            <DialogContent>
                <Typography>{t("dismount_dialog_content")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button sx={{ color: "black", mr: 1 }} onClick={() => setOpenDismountDialog(false)}>
                    {t("dismount_continue_creation")}
                </Button>
                <Button variant={"contained"} color={"error"} disableElevation onClick={onDismount}>
                    {t("dismount_give_up_creation")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}