import React, {useState} from "react";
// MUI
import {
    Alert,
    Button,
    Collapse,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {CheckCircle, Refresh} from "@mui/icons-material";
// Regexes
import {isValidSimpleAlphanumeric, regexValidUrl} from "@/utils";
// Components
import {PaperLabelled} from "@/components/UI";

const GeneratorContentSection = (props: any) => {

    const {
        gs1,
        qrcode, setQRCode,
        shortUrlPrefix,
        submitted, onSubmit, onUnsubmit,
        logo,
        domains,
        t
    } = props

    const [openResolutionSelect, setOpenResolutionSelect] = useState<boolean>(false)
    const [openShortUrl, setOpenShortUrl] = useState<boolean>(false)

    // render
    const renderContentFields = () => {

        const renderTypeSelector = () => {
            return (
                <Grid item xs={12} md={gs1 ? 4 : 6}>
                    <FormControl fullWidth>
                        <InputLabel id={"qrcode_type"}>{t("products:qrcode_type")}</InputLabel>
                        <Select
                            label={t("products:qrcode_type")}
                            labelId={"qrcode_type"}
                            value={"Packaging"}
                            disabled
                        >
                            <MenuItem value={"Packaging"}>Packaging</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        const renderResolutionSelector = () => {
            return (
                <Grid item xs={12} md={gs1 ? 4 : 6}>
                    <FormControl fullWidth>
                        <InputLabel id={"qrcode_resolution"}>{t("products:qrcode_resolution")}</InputLabel>
                        <Select
                            label={t("products:qrcode_resolution")}
                            labelId={"qrcode_resolution"}
                            open={openResolutionSelect}
                            onOpen={() => setOpenResolutionSelect(true)}
                            onClose={() => setOpenResolutionSelect(false)}
                            value={qrcode.data.resolution}
                            onChange={(e) => {
                                setQRCode({...qrcode, data: {...qrcode.data, resolution: e.target.value}})
                            }}
                        >
                            <MenuItem value={"dynamic"} disabled>Static</MenuItem>
                            <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                            <MenuItem value={"dynamic-pro"}>Dynamic Pro</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        const renderContentSelector = () => {
            return (
                <Grid item xs={12}>
                    <TextField
                        value={qrcode.data.url || ""}
                        onChange={(e) => setQRCode({...qrcode, data: { ...qrcode.data, url: e.target.value}})}
                        label={t("products:destination_url")}
                        required
                        fullWidth
                        inputProps={{readOnly: true}}
                        error={!qrcode.data.url || !regexValidUrl(qrcode.data.url)}
                        sx={{ minWidth: 230 }}
                    />
                </Grid>
            )
        }

        const renderShortUrlSelector = () => {

            const returnDomainPrefix = () => {
                let prefix = shortUrlPrefix + "/"
                if (!!domains && !!qrcode.data.domain_id) {
                    let domain = domains.filter((domain: any) => domain.domain_id === qrcode.data.domain_id)
                    prefix = 'https://'+domain[0].domain_name+'/a/'
                }
                return prefix
            }

            return (
                <Grid item xs={12}>
                    {
                        openShortUrl ?
                            <PaperLabelled label={t("products:short_url")} sx={{ width: "100%" }} paperPadding={"8px 16px"}>
                                <TextField
                                    size={"small"}
                                    fullWidth
                                    variant={"standard"}
                                    error={!isValidSimpleAlphanumeric(qrcode.data.preferred_short_url) || submitted === undefined}
                                    helperText={submitted === undefined ? t("short_url_already_in_use") : ""}
                                    value={qrcode.data.preferred_short_url}
                                    placeholder={t("products:change_short_url")}
                                    onChange={(e) => {
                                        let _qrcode = {...qrcode}
                                        _qrcode.data.preferred_short_url = e.target.value
                                        setQRCode(_qrcode)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{returnDomainPrefix()}</InputAdornment>
                                    }}
                                />
                            </PaperLabelled>
                            :
                            <Button size={"small"} color={"secondary"} onClick={() => setOpenShortUrl(!openShortUrl)}>
                                {t("products:change_short_url")}
                            </Button>
                    }
                </Grid>
            )
        }

        const renderDomainsSelector = () => {

            if (qrcode.data.resolution !== "dynamic-pro") return <></>

            if (!domains || domains.length === 0) {
                return (
                    <Grid item xs={12}>
                        <Alert severity={"error"}>
                            {t("no_domains_found")}
                        </Alert>
                    </Grid>
                )
            }

            return (
                <Grid item xs={12}>
                    <FormControl fullWidth error={!qrcode.data.domain_id} size={"small"}>
                        <InputLabel id={"domain"}>{t("products:domain")}</InputLabel>
                        <Select
                            label={t("products:domain")}
                            labelId={"domain"}
                            value={qrcode.data.domain_id ? qrcode.data.domain_id : ""}
                            onChange={(e) => {
                                let _qrcode = {...qrcode}
                                _qrcode.data.domain_id = e.target.value
                                setQRCode(_qrcode)
                            }}

                        >
                            <MenuItem value={""} disabled>{t("no_domain_selected")}</MenuItem>

                            {!!domains && domains.filter((domain: any) => {
                                return domain.activated;
                            }).map((domain: any, i: any) => (
                                <MenuItem key={i} value={domain.domain_id}>{domain.domain_name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        const renderRedundancyContent = () => {

            const changeRedundancy = (e: any) => {
                let _qrcode = {...qrcode};
                _qrcode.settings.redundancy = e.target.value;
                setQRCode(_qrcode);
            }

            return (
                <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id={"qrcode_type"}>{t("products:redundancy")}</InputLabel>
                            <Select
                                label={t("products:redundancy")}
                                labelId={"qrcode_type"}
                                value={qrcode.settings.redundancy}
                                onChange={(e: any) => changeRedundancy(e)}
                            >
                                <MenuItem value={"L"}>{t("weak")}</MenuItem>
                                <MenuItem value={"M"}>{t("medium")}</MenuItem>
                                <MenuItem value={"H"}>{t("strong")}</MenuItem>
                                <MenuItem value={"Q"}>{t("quality")}</MenuItem>
                            </Select>
                        </FormControl>
                </Grid>
            )
        }


        return (
            <Grid container justifyContent={"space-between"} spacing={2}>
                {renderTypeSelector()}
                {renderResolutionSelector()}
                {gs1 && renderRedundancyContent()}
                {renderContentSelector()}
                {renderDomainsSelector()}
                {renderShortUrlSelector()}

                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                        variant={"contained"}
                        disableElevation
                        onClick={onSubmit}
                        disabled={
                            !qrcode.data.label ||
                            !qrcode.data.url ||
                            !regexValidUrl(qrcode.data.url) ||
                            (qrcode.data.resolution === "dynamic-pro" &&
                                (!qrcode.data.domain_id || !isValidSimpleAlphanumeric(qrcode.data.preferred_short_url))
                            )}
                    >
                        {t("products:submit")}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderSubmittedContent = () => {
        return (
            <Grid container justifyContent={"center"} spacing={2} mt={10}>
                <Grid item xs={12} lg={12}>
                    <Stack direction={"row"} spacing={2} sx={{ mt: "4px" }} justifyContent={"center"}>
                        <CheckCircle color={"success"} />
                        <Typography>{t("products:qrcode_content_submitted")}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={12} sx={{ textAlign: { xs: "center", md: "center" } }}>
                    <Button onClick={onUnsubmit} startIcon={<Refresh />} color={"secondary"} disabled={logo.replacing}>
                        {t("products:change_qrcode_content")}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid item xs={12} md={gs1 ? 11 : 4} lg={gs1 ? 11 : 5}>
            <PaperLabelled
                label={t("products:qrcode_general_info")}
                background={submitted ? "rgba(175,201,40,0.2)" : "#fff"}
                paperStyle={{ minHeight: 338 }}
                sx={{ minHeight: 331 }}
                paperMaxHeight={338}
            >
                <Collapse in={submitted === true} sx={{ pt: "4px" }}>{renderSubmittedContent()}</Collapse>
                <Collapse in={!submitted} sx={{ pt: "4px" }}>{renderContentFields()}</Collapse>
            </PaperLabelled>
        </Grid>
    )
}

export default GeneratorContentSection